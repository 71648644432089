<template>
    <pui-lightbox
        ref="streamSystemAddEdit"
        :default-header-label="modalTitle"
        :show-lightbox-close-icon="false"
        :default-footer-confirm-label="$t('save')"
        :default-footer-cancel-label="$t('cancel')"
        :on-confirm-callback="save"
        :fit-content="true"
        :on-close-callback="closeModal"
    >
        <template #default>
            <validation-observer ref="observer" >
                    <div class="fit-modal-width" />
                    <pui-form-inline-notification
                        v-if="errorMessage"
                        :title="errorMessage"
                        text=""
                    />
                    <pui-grid-container>
                        <pui-grid-row>
                            <pui-grid-column :cols="{s:3, m:3, l:3, xl:3, xxl: 3}">
                                <text-input
                                    :name="$t('id').toString()"
                                    type="number"
                                    :rules="validationRules.id"
                                    :input-value="streamSystem.id"
                                    :placeholder="$t('enterNumber').toString()"
                                    :disabled="isEditMode"
                                    @change="value => streamSystem.id = value"
                                />
                            </pui-grid-column>
                            <pui-grid-column :cols="{s:9, m:9, l:9, xl:9, xxl: 9}">
                                <text-input
                                    :name="$t('name').toString()"
                                    :rules="validationRules.name"
                                    :input-value="streamSystem.name"
                                    :placeholder="$t('pleaseEnterText').toString()"
                                    @change="value => streamSystem.name = value"
                                />
                            </pui-grid-column>
                        </pui-grid-row>

                        <pui-grid-row>
                            <pui-grid-column :cols="{s:12, m:12, l:12, xl:12, xxl: 12}">
                                <textarea-input
                                    :name="$t('description').toString()"
                                    :rows="8"
                                    :input-value="streamSystem.description"
                                    :placeholder="$t('pleaseEnterText').toString()"
                                    @change="value => streamSystem.description = value"
                                />
                            </pui-grid-column>
                        </pui-grid-row>

                        <pui-grid-row>
                            <pui-grid-column :cols="{s:6, m:6, l:6, xl:6, xxl: 6}">
                                <select-box
                                    :name="$t('assetLevel').toString()"
                                    :rules="validationRules.assetLevel"
                                    :select-box-options="assetLevels"
                                    :input-value="streamSystem.assetPermissionType"
                                    :placeholder="$t('select').toString()"
                                    @change="value => streamSystem.assetPermissionType = value"
                                />
                            </pui-grid-column>
                            <pui-grid-column :cols="{s:6, m:6, l:6, xl:6, xxl: 6}">
                                <select-box
                                    :name="$t('useCase').toString()"
                                    :rules="validationRules.useCase"
                                    :select-box-options="useCases"
                                    :input-value="streamSystem.useCaseId"
                                    :placeholder="$t('select').toString()"
                                    @change="value => streamSystem.useCaseId = value"
                                />
                            </pui-grid-column>
                        </pui-grid-row>
                    </pui-grid-container>
            </validation-observer>
        </template>
    </pui-lightbox>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator';
import TextInput from '@/components/shared/inputs-with-validations/text-input.vue';
import TextareaInput from '@/components/shared/inputs-with-validations/textarea-input.vue';
import { StreamSystemService } from '@/services';
import { Application, SelectOption } from '@/models';
import SelectBox from '@/components/shared/inputs-with-validations/select-box.vue';
import { StreamSystemModel } from '@/models/stream-system-item';
import { EventBus } from '@/utils';
import { StreamSystemAssetPermissionType } from '@/models/stream-system-asset-permission-type';
import ComponentSecurity from '@/mixins/component-security';

@Component({
    name: 'stream-system-assets-add-edit',
    components: {
        TextInput,
        SelectBox,
        TextareaInput,
    },
})
export default class StreamSystemAssetsAddEdit extends Mixins(ComponentSecurity) {
    private streamSystem: StreamSystemModel = {
        id: undefined,
        name: '',
        description: '',
        assetPermissionType: undefined,
        useCaseId: undefined,
    };

    private streamSystemService: StreamSystemService = new StreamSystemService();

    private assetLevels: SelectOption[] = [];
    private useCases: SelectOption[] = [];
    private isEditMode = false;
    private errorMessage: string | undefined = '';

    mounted() {
        this.useCases = this.$store.getters.getUseCases.map((a: Application): SelectOption => {
            return {
                label: a.name,
                value: a.id,
            };
        });
        this.assetLevels = [
            {
                label: this.$t('powerPlant').toString(),
                value: StreamSystemAssetPermissionType.POWER_PLANT,
            },
            {
                label: this.$t('unit').toString(),
                value: StreamSystemAssetPermissionType.UNIT,
            },
        ];
    }

    private newStreamSystem() {
        const emptyStreamSystem: StreamSystemModel = {
            id: undefined,
            name: '',
            description: '',
            assetPermissionType: undefined,
            useCaseId: undefined,
        };
        this.streamSystem = {...emptyStreamSystem};
    }

    private get modalTitle(): string {
        return this.isEditMode ? this.$t('edit').toString() : this.$t('addNew').toString();
    }

    private async save(): Promise<void> {
        if (!await this.isValidForm()) {
            return;
        }

        try {
            let currentStreamSystem: StreamSystemModel;
            const { ...systemStreamInfo } = this.streamSystem;
            if (this.isEditMode) {
                currentStreamSystem = (await this.streamSystemService.editStreamSystem(systemStreamInfo.id as number, systemStreamInfo)).result;
            } else {
                currentStreamSystem = (await this.streamSystemService.addStreamSystem(systemStreamInfo)).result;
            }
            this.streamSystem = currentStreamSystem;
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
            this.$emit('reload-stream-system');
            this.closeModal();
        } catch (e: any) {
            if (e.response?.data) {
                this.errorMessage = e.response?.data?.errors?.join(', ') ?? this.$t('errorTitlePebbleErrorsList').toString();
            } else {
                this.errorMessage = e instanceof Error ? e.message : this.$t('errorTitlePebbleErrorsList').toString();
            }
        }
    }

    public open(streamSystem?: StreamSystemModel, isEditMode = false): void {
        this.streamSystem = {...this.streamSystem, ...streamSystem};
        this.isEditMode = isEditMode;
        (this.$refs.streamSystemAddEdit as any)?.open();
    }

    public closeModal(): void {
        this.newStreamSystem();
        this.isEditMode = false;
        this.errorMessage = '';
        (this.$refs.streamSystemAddEdit as any)?.close();
    }

    private get validationRules(): any {
        return {
            id: {required: true, min_value: 1},
            name: {required: true},
            assetLevel: {required: true},
            useCase: {required: true},
        };
    }

    private async isValidForm(): Promise<boolean> {
        return await (this.$refs?.observer as any)?.validate();
    }

    @Watch('streamSystem', { deep: true })
    private onStreamSystemChanged() {
        this.errorMessage = undefined;
    }
}
</script>

<style lang="less" scoped>
@import "~@/components/asset-master-data/common-assets/modal-style.less";

/deep/ .pui-form-input-field input {
    padding-left: 0.4rem;
}

/deep/ .pui-form-inline-notification__close__clickable {
    display: none;
}
</style>
