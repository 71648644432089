import { render, staticRenderFns } from "./stream-system-assets-add-edit.vue?vue&type=template&id=f9226b42&scoped=true"
import script from "./stream-system-assets-add-edit.vue?vue&type=script&lang=ts"
export * from "./stream-system-assets-add-edit.vue?vue&type=script&lang=ts"
import style0 from "./stream-system-assets-add-edit.vue?vue&type=style&index=0&id=f9226b42&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9226b42",
  null
  
)

export default component.exports