import { render, staticRenderFns } from "./sinergy-life-location-header.vue?vue&type=template&id=491ff24f&scoped=true"
import script from "./sinergy-life-location-header.vue?vue&type=script&lang=ts"
export * from "./sinergy-life-location-header.vue?vue&type=script&lang=ts"
import style0 from "./sinergy-life-location-header.vue?vue&type=style&index=0&id=491ff24f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "491ff24f",
  null
  
)

export default component.exports