<template>
    <div class="navbar flex-container">
        <adam-header-bar
            :user-name.prop="userName"
            :email-address.prop="userEmail"
            :show-notifications.prop="false"
            :class="[
                { 'bg-dev': isDevelopmentEnv },
                { 'bg-uat': isUatEnv },
            ]"
            @apps-click="openDrawer"
        >
            <span slot="headerContent" class="app-logo">
                <a :href="isAssetOverviewApp ? '/asset-overview' : '/'">
                    <img
                        class="app-logo-img"
                        src="~adam.ui-core/assets/brand/logo-coode-white.svg"
                    >
                </a>
            </span>
            <span slot="headerContent">
                <h4>Master Data</h4>
            </span>
            <div slot="right">
                <select
                    v-model="selectedLanguage"
                    id="language-select"
                    :class="[
                        'default-select language-select',
                        { 'language-select--development': isDevelopmentEnv },
                        { 'language-select--uat': isUatEnv },
                    ]"
                    @change="changeLanguage()"
                >
                    <option v-for="(lang, index) of languages" :key="index" :value="lang">
                        {{lang}}
                    </option>
                </select>
            </div>
            <div slot="profileDetails">
                <user-profile />
            </div>
        </adam-header-bar>
        <app-drawer
            v-if="applications"
            ref="appDrawer"
            :data.prop="getApps"
            :labels.prop="labelsForAppDrawer"
            :onClick.prop="clickApp"
        >
            <span slot="appLogo" class="app-logo">
                <img src="~adam.ui-core/assets/brand/logo-coode-rgb.svg">
            </span>
        </app-drawer>
        <snackbar />
    </div>
</template>

<script lang="ts">

import { Component, Mixins } from 'vue-property-decorator';
import UserProfile from './user-profile/user-profile.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import { Application } from '@/models';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import ComponentSecurity from '@/mixins/component-security';
import { LocalStorageHelper, LANGUAGES } from '@/utils';
import { namespace } from 'vuex-class';
import { Environments } from '@coode/fe-sdk';

const userModule = namespace('UserModule');

@Component({
    components: {
        userProfile: UserProfile,
        snackbar: Snackbar,
    },
})
export default class Navbar extends Mixins(ComponentSecurity) {
    @userModule.Getter
    private userName!: () => string | undefined;

    @userModule.Getter
    private userEmail!: () => string | undefined;

    private isAssetOverviewApp = false;
    private languages: any[] = [];
    private selectedLanguage = '';
    private applications: Application[] = [];

    private async mounted(): Promise<void> {
        this.loadLanguages();
        this.checkForAssetOverviewApp();
    }

    get getApps() {
        return this.$store.getters.getUseCases.map((app: Application): MenuEntry => ({
            id: app?.useCaseId?.toString(),
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: any, b: any) => {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    get labelsForAppDrawer() {
        return { applications: this.$t('appsWithAccess'), miscellaneous: this.$t('appsWithoutAccess') };
    }

    private checkForAssetOverviewApp() {
        if (this.$route.query.app === 'true') {
            this.isAssetOverviewApp = true;
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    private clickApp(app: Application) {
        if (app.url) {
             window.open(app.url);
        }
    }

    private openDrawer() {
        (this.$refs.appDrawer as any).open = true;
    }

    private loadLanguages(): void {
        this.languages = Object.keys(LANGUAGES);
        this.selectedLanguage = LocalStorageHelper.getSelectedLanguage() ?? this.languages[0];
        if (this.selectedLanguage) {
            this.selectLanguage();
        }
    }

    private selectLanguage(): void {
        LocalStorageHelper.setSelectedLanguage(this.selectedLanguage);
        this.$store.commit('selectedLanguage', this.selectedLanguage.toLowerCase());
        this.$i18n.locale = LANGUAGES[this.selectedLanguage];
    }

    private changeLanguage(): void {
        this.selectLanguage();
        window.location.reload();
    }

    private get isDevelopmentEnv(): boolean {
        return ['dev', 'development'].includes(process.env.VUE_APP_ENVIRONMENT as Environments);
    }
    private get isUatEnv(): boolean {
        return process.env.VUE_APP_ENVIRONMENT === 'uat';
    }
}

</script>

<style scoped lang="less">
@import "~@/variables.less";

.app-logo {
    img {
        height: 90%;
        margin-right: 1rem;
        padding: 6px 6px 3px 6px;
    }

    .app-logo-img {
        height: 4.5rem;
    }
}

.language-select {
    padding: 0.7rem;
    border-radius: 0.4rem;
    vertical-align: middle;
    border-color: @warm-grey !important;
    &:focus {
        outline: none;
    }
}

::v-deep .adam-header__separator.adam-header-bar {
    background: white;
}

::v-deep .bg-uat {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-uat;

        .adam-header-user__icon.adam-header-bar path {
            fill:@uniper-uat;
        }

        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-uat;
        }

        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-uat-darker;
        }

        .adam-header__logo.adam-header-bar:hover {
            --fill: @uniper-uat-darker;
        }
    }
}

::v-deep .bg-dev {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-dev;

        .adam-header-user__icon.adam-header-bar path {
            fill: @uniper-dev;
        }

        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-dev;
        }

        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-dev-darker;
        }

        .adam-header__logo.adam-header-bar:hover {
            --fill: @uniper-dev-darker;
        }
    }
}
</style>
