<template>
    <div class="container">
        <pui-headline type="h3">
            {{ $t('synergyLife') }}
        </pui-headline>
        <pui-grid-row>
            <pui-grid-column :cols="{s:12, m:8, l:8, xl:8, xxl: 8}">
                <custom-filter
                    v-if="dataLoaded"
                    :filters="filters"
                    :on-filter-change="onFilterChange"
                />
            </pui-grid-column>
            <pui-grid-column
                :cols="{s:12, m:4, l:4, xl:4, xxl: 4}"
                class="add-new-button-container"
                v-if="hasMasterDataAdmin"
            >
                <pui-button
                    v-on:click="onAddNew"
                    icon="add"
                    class="add-new-button">
                    {{ $t("addNew") }}
                </pui-button>
            </pui-grid-column>
        </pui-grid-row>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import CustomFilter from '@/components/filter/custom-filter.vue';
import {Mixins, Prop} from 'vue-property-decorator';
import ComponentSecurity from '@/mixins/component-security';
import {AssetService} from '@/services';
import {Asset, IdTypeSid, SearchPaginationQueryModel} from '@/models';
import {AssetTypeOptions, EventBus} from '@/utils';
import {Filter} from '@/models/custom-filter/filter';
import {FilterValue} from '@/models/custom-filter/filter-value';

@Component({
    name: 'sinergy-life-location-header',
    components: {CustomFilter},
})
export default class SinergyLifeLocationHeader extends Mixins(ComponentSecurity) {
    @Prop({ required: true }) private selectedCountries!: string[];
    @Prop({ required: true }) private selectedAssetTypes!: string[];

    private assetService: AssetService = new AssetService();
    private filters: Filter[] = [];
    private dataLoaded = false;

    /* PRIMITIVE METHODS */
    private mounted(): void {
        this.init();
    }

    /* METHODS */
    private async init(): Promise<void> {
        this.dataLoaded = false;
        const tempCountries = await this.getCountries();
        const countries = this.generateCountryFilter(tempCountries.map((country) => {
            return {
                displayName: country.description,
                value: country.description,
            };
        }));
        const assets = this.generateAssetsFilter(AssetTypeOptions);
        this.filters = [countries, assets];
        this.dataLoaded = true;
        this.onFilterChange({
            country: this.selectedCountries,
            asset: this.selectedAssetTypes,
        });
    }

    private generateCountryFilter(countries: FilterValue[]): Filter {
        return {
            name: 'country',
            displayName: this.$t('country').toString(),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            appliedValues: {
                options: this.selectedCountries,
            },
            selectedValues: {
                options: this.selectedCountries,
            },
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: this.$t('country').toString(),
                options: countries,
            },
        };
    }

    private generateAssetsFilter(assets: FilterValue[]): Filter {
        return {
            name: 'asset',
            displayName: this.$t('assetType').toString(),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            appliedValues: {
                options: this.selectedAssetTypes,
            },
            selectedValues: {
                options: this.selectedAssetTypes,
            },
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: this.$t('assetType').toString(),
                options: assets,
            },
        };
    }

    /* ON CLICK BUTTONS */
    private onAddNew(): void {
        this.$router.push({
            name: 'sinergy-life-location-new',
        });
    }

    private onFilterChange(data: any): void {
        this.$emit('filter-change', data);
    }

    /* API CALLS */
    private async getCountries(): Promise<Asset[]> {
        try {
            const filter: SearchPaginationQueryModel = {
                page: 1,
                size: 1000,
                term: '',
            };
            const {result: {items}} = await this.assetService.getByIdTypeSid(IdTypeSid.COUNTRY, filter);
            return items;
        } catch (err: any) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorGettingAssets');
            return [];
        }
    }

}
</script>

<style scoped>
.container {
    margin: 16px 0 16px 0;
    width: 100%;
}

.add-new-button-container {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: .8rem;
}
</style>
